import {defineNuxtRouteMiddleware, navigateTo} from 'nuxt/app';
import {URL_ACCOUNT_ORDERS_PAGE} from "~/constants/urls";
import {removeTrailingSlashes} from "~/utils/helpers";

export default defineNuxtRouteMiddleware(async (to) => {
  const onboardingStore = useOnboardingStore();

  if (to.meta.requiresAccessCheck && !onboardingStore.isRouteAllowed(removeTrailingSlashes(to.path))) {
    return navigateTo(URL_ACCOUNT_ORDERS_PAGE, {external: true});
  }
});
